<template>
  <header class="fixed-top d-flex site-header border-bottom-gray-200">
    <h1 class="d-none">Wakeo TSER</h1>
    <NavMenu v-if="isNavMenuIconDisplay"/>

    <div
      class="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1 bg-white"
    >
    
      <a class="d-block wakeo-logo" href="/" aria-label="$t('logo-link.label')">
        <!-- <WakeoLogo :width="40" class="mx-3 d-block d-sm-none" /> -->
        <WakeoLogo :width="logoWidth" class="mx-3 d-none d-sm-block" />
  
      </a>
      <SearchBar class="flex-grow-1"></SearchBar>

      <div class="mx-2 d-flex">
        <Profile />
        <DisplayFor shipper>
          <NotificationsList />
        </DisplayFor>
        <DisplayFor carrier>
          <NotificationsList />
        </DisplayFor>
      </div>
    </div>
  </header>
</template>

<script>
import DisplayFor from "@/components/DisplayFor";
import NavMenu from "@/components/SiteHeader/NavMenu";
import NotificationsList from "@/features/notifications/components/NotificationsList/NotificationsList";
import SearchBar from "@/features/search/components/SearchBar";
import WakeoLogo from "@/components/SiteHeader/WakeoLogo";
import Profile from "@/components/SiteHeader/Profile";
import windowSizeMixin from "@/mixins/windowSizeMixin";


export default {
  name: "SiteHeader",
  components: { DisplayFor, NavMenu, NotificationsList, SearchBar, WakeoLogo, Profile },
  mixins: [windowSizeMixin],
  computed: {
    isNavMenuIconDisplay() {
      return this.windowSize.width < 1280;
    },
    logoWidth() {
      return this.windowSize.width < 1440 ? 80 : 300;
    },
  },
};
</script>

<style lang="scss" scoped>
.site-header {
  z-index: $zindex-above-all;
  min-height: 65px;
}
</style>
