<template>
  <div :style="{ width: `${width}px` }">
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="15" viewBox="0 0 120 15" fill="none">
      <defs>
        <linearGradient id="paint0_linear" x1="16.3205" y1="3.57984" x2="4.68319" y2="8.90771" gradientUnits="userSpaceOnUse">
          <stop stop-color="#289DCC"/>
          <stop offset="0.802083" stop-color="#73E9E2"/>
        </linearGradient>
      </defs>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2104 1.96135C15.2677 1.96135 14.5071 2.73267 14.5071 3.66469C14.5071 4.60742 15.2784 5.36803 16.2104 5.36803C17.1424 5.36803 17.9138 4.59671 17.9138 3.66469C17.9138 2.72196 17.1424 1.96135 16.2104 1.96135ZM16.6064 11.6938L10.0073 2.72713C9.69666 2.30933 9.25743 2.06293 8.77535 1.98794L8.74969 1.9847C8.67439 1.97517 8.606 1.96651 8.52896 1.96651C8.10044 1.93438 7.65051 2.05222 7.27556 2.32004C6.85776 2.63071 6.61136 3.06994 6.53637 3.55201C6.46138 4.02338 6.56851 4.52688 6.87918 4.94468L13.489 13.9006C13.7997 14.3184 14.2389 14.5648 14.721 14.6398L14.7466 14.643C14.8219 14.6526 14.8903 14.6612 14.9674 14.6612C15.3959 14.6934 15.8458 14.5755 16.2208 14.3077C16.6386 13.997 16.885 13.5578 16.96 13.0757C17.0242 12.6044 16.9171 12.1116 16.6064 11.6938ZM2.15483 1.98789C2.63691 2.06288 3.07613 2.30928 3.38681 2.72708L9.99663 11.683C10.3073 12.1008 10.4144 12.6043 10.3394 13.0757C10.2644 13.5578 10.0181 13.997 9.60025 14.3077C9.18245 14.6183 8.67895 14.7255 8.20758 14.6505C7.72551 14.5755 7.28628 14.3291 6.97561 13.9113L0.365786 4.95535C0.0551135 4.53755 -0.0520149 4.03404 0.022975 3.56268C0.0979648 3.0806 0.34436 2.64137 0.762161 2.3307C1.17996 2.02003 1.68346 1.9129 2.15483 1.98789Z" fill="url(#paint0_linear)"/>
      <path d="M29.4809 13.3934L31.3156 3.66944H34.5263L36.6546 13.3934H34.3796L32.1962 3.74283H33.4989L31.5357 13.3934H29.4809ZM28.7837 13.3934L28.7653 10.9533H30.7835L30.8018 13.3934H28.7837ZM27.4627 13.3934L24.7656 3.33919H27.6461L30.1597 13.3934H27.4627ZM35.1318 13.3934L35.1501 10.9533H37.1683V13.3934H35.1318ZM35.9574 13.3934L38.1958 3.33919H40.8928L38.471 13.3934H35.9574Z" fill="#0D4B5C"/>
      <path d="M47.7191 13.3934V10.4212H47.2237V7.1921C47.2237 6.67838 47.1014 6.29309 46.8568 6.03623C46.6122 5.77936 46.2207 5.65093 45.6826 5.65093C45.4135 5.65093 45.0588 5.65705 44.6184 5.66928C44.1781 5.68151 43.7255 5.69986 43.2607 5.72432C42.7959 5.74879 42.374 5.77325 41.9948 5.79771V3.32084C42.2761 3.29638 42.6064 3.27192 42.9855 3.24745C43.3647 3.22299 43.7561 3.20464 44.1597 3.19241C44.5634 3.18018 44.9426 3.17406 45.2973 3.17406C46.3247 3.17406 47.187 3.32084 47.8842 3.6144C48.5937 3.90795 49.1318 4.3544 49.4988 4.95374C49.8657 5.54085 50.0492 6.2992 50.0492 7.22879V13.3934H47.7191ZM44.5083 13.6503C43.7867 13.6503 43.1507 13.5219 42.6002 13.265C42.0621 13.0082 41.6401 12.6412 41.3343 12.1642C41.0285 11.6749 40.8756 11.0939 40.8756 10.4212C40.8756 9.68732 41.0652 9.08797 41.4444 8.62318C41.8235 8.14615 42.3556 7.79755 43.0406 7.57739C43.7255 7.34499 44.5145 7.22879 45.4074 7.22879H47.554V8.86169H45.389C44.8753 8.86169 44.4778 8.99012 44.1964 9.24698C43.9274 9.49161 43.7928 9.82798 43.7928 10.2561C43.7928 10.6597 43.9274 10.99 44.1964 11.2468C44.4778 11.4915 44.8753 11.6138 45.389 11.6138C45.7193 11.6138 46.0128 11.5587 46.2697 11.4486C46.5388 11.3263 46.7589 11.1245 46.9302 10.8432C47.1014 10.5619 47.1993 10.1705 47.2237 9.66897L47.9209 10.4029C47.8598 11.1 47.6885 11.6872 47.4072 12.1642C47.1381 12.6412 46.7589 13.0082 46.2697 13.265C45.7926 13.5219 45.2055 13.6503 44.5083 13.6503Z" fill="#0D4B5C"/>
      <path d="M58.7045 13.3934L55.5304 8.69657H54.1177L58.2091 3.33919H61.2914L57.4385 8.32962L57.4752 6.89854L62.007 13.3934H58.7045ZM51.6225 13.3934V0H54.558V13.3934H51.6225Z" fill="#0D4B5C"/>
      <path d="M66.6339 13.742C65.7777 13.742 65.0194 13.5953 64.3589 13.3017C63.7106 13.0082 63.1663 12.6167 62.726 12.1275C62.2979 11.626 61.9676 11.0695 61.7352 10.4579C61.515 9.83409 61.405 9.19806 61.405 8.54979V8.18285C61.405 7.51012 61.515 6.86796 61.7352 6.25639C61.9676 5.63259 62.2979 5.07606 62.726 4.5868C63.1541 4.09754 63.6861 3.71225 64.3222 3.43092C64.9704 3.13737 65.7043 2.99059 66.5238 2.99059C67.6002 2.99059 68.5053 3.23522 69.2392 3.72448C69.9853 4.20151 70.5541 4.83143 70.9455 5.61424C71.3369 6.38482 71.5326 7.22879 71.5326 8.14615V9.1369H62.6342V7.46731H69.7529L68.7989 8.23789C68.7989 7.63855 68.7133 7.12483 68.542 6.69672C68.3708 6.26862 68.1139 5.94449 67.7714 5.72432C67.4412 5.49193 67.0253 5.37573 66.5238 5.37573C66.0101 5.37573 65.5759 5.49193 65.2212 5.72432C64.8665 5.95672 64.5974 6.2992 64.4139 6.75177C64.2304 7.1921 64.1387 7.7364 64.1387 8.38467C64.1387 8.98401 64.2243 9.50996 64.3955 9.96252C64.5668 10.4029 64.8359 10.7453 65.2028 10.99C65.5698 11.2346 66.0468 11.3569 66.6339 11.3569C67.1721 11.3569 67.6124 11.2529 67.9549 11.045C68.2974 10.8371 68.5298 10.5802 68.6521 10.2744H71.3491C71.2024 10.9472 70.9149 11.5465 70.4868 12.0724C70.0587 12.5984 69.5205 13.0082 68.8723 13.3017C68.224 13.5953 67.4779 13.742 66.6339 13.742Z" fill="#0D4B5C"/>
      <path d="M77.4592 13.742C76.5785 13.742 75.7957 13.6075 75.1107 13.3384C74.4258 13.0571 73.8448 12.6779 73.3678 12.2009C72.903 11.7239 72.5482 11.1796 72.3036 10.568C72.059 9.94418 71.9367 9.28979 71.9367 8.60483V8.18285C71.9367 7.48565 72.059 6.82515 72.3036 6.20135C72.5605 5.56531 72.9274 5.00878 73.4044 4.53176C73.8937 4.05473 74.4808 3.68167 75.1658 3.41258C75.8507 3.13125 76.6152 2.99059 77.4592 2.99059C78.3276 2.99059 79.0982 3.13125 79.7709 3.41258C80.4559 3.68167 81.0369 4.05473 81.5139 4.53176C81.9909 5.00878 82.3579 5.56531 82.6147 6.20135C82.8716 6.82515 83 7.48565 83 8.18285V8.60483C83 9.28979 82.8777 9.94418 82.6331 10.568C82.3885 11.1796 82.0276 11.7239 81.5506 12.2009C81.0858 12.6779 80.5109 13.0571 79.826 13.3384C79.141 13.6075 78.3521 13.742 77.4592 13.742ZM77.4592 11.2652C78.0341 11.2652 78.5111 11.1429 78.8903 10.8982C79.2694 10.6536 79.5569 10.3111 79.7526 9.87079C79.9605 9.43045 80.0645 8.9412 80.0645 8.40301C80.0645 7.82813 79.9605 7.32664 79.7526 6.89854C79.5446 6.45821 79.245 6.10961 78.8536 5.85275C78.4744 5.59589 78.0096 5.46746 77.4592 5.46746C76.921 5.46746 76.4562 5.59589 76.0648 5.85275C75.6734 6.10961 75.3737 6.45821 75.1658 6.89854C74.9701 7.32664 74.8722 7.82813 74.8722 8.40301C74.8722 8.9412 74.9701 9.43045 75.1658 9.87079C75.3615 10.3111 75.655 10.6536 76.0464 10.8982C76.4378 11.1429 76.9088 11.2652 77.4592 11.2652Z" fill="#0D4B5C"/>
    </svg>
  </div>
</template>

<script>
const DEFAULT_WIDTH = 75;
const DEFAULT_HEIGHT = 40;
const ASPECT_RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT;

export default {
  name: "WakeoLogo",
  props: {
    white: Boolean,
    width: { type: Number, default: DEFAULT_WIDTH },
  },
  computed: {
    height() {
      return this.width / ASPECT_RATIO;
    },
  },
};
</script>
