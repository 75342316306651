import store from "@/store";
import { PAGES, TRANSPORT_PAGES } from "@/router/pageNames";
import adminRoutes from "@/features/admin/admin-routes";
import Default from "@/components/Layouts/Default";
import Bare from "@/components/Layouts/Bare";

// I don't want to remove this code because it is really going to be useful the next time we need to
// protect a page with a feature toggle
// function isDisabledGuard(feature) {
//   return function guard(to, from, next) {
//     const { enabled } = store.state.features;
//     if (!enabled || enabled[feature] !== false) {
//       next();
//       return;
//     }

//     next({ name: PAGES.NOT_FOUND });
//   };
// }

function isCarrierGuard(to, from, next) {
  if (store.getters.isUserCarrier) {
    next();
    return;
  }

  next({ name: PAGES.NOT_FOUND });
}

function isShipperGuard(to, from, next) {
  if (store.getters.isUserShipper) {
    next();
    return;
  }
  next({ name: PAGES.NOT_FOUND });
}

const appRoutes = [
  {
    path: "/",
    redirect: "/cockpit",
  },
  {
    path: "",
    name: "no-layout",
    component: Bare,
    children: [
      {
        path: "/twt",
        name: PAGES.TRANSPORT_TRACKING,
        component: () =>
          import(
            /* webpackChunkName: "transportWaypointTracking" */
            "../features/transportStatusUpdate/views/TransportWaypointTracking.vue"
          ),
        props: true,
        meta: { unauthenticated: true, title: "transport-waypoint-tracking.page-title" },
      },
      {
        path: "/no-organization",
        name: PAGES.NO_ORGANIZATION,
        component: () =>
          import(
            /* webpackChunkName: "noOrganization" */
            "../features/organization/views/NoOrganizationPage.vue"
          ),
        meta: {
          unauthenticated: true,
          title: "no-organization-page.page-title",
        },
      },
      {
        path: "/not-authorized",
        name: PAGES.NOT_AUTHORIZED,
        component: () =>
          import(
            /* webpackChunkName: "notAuthorized" */
            "../features/errorPages/notAuthorized/views/NotAuthorizedPage.vue"
          ),
        meta: {
          unauthenticated: true,

          title: "not-authorized-page.page-title",
        },
      },
      {
        path: "/not-found",
        name: PAGES.NOT_FOUND,
        component: () =>
          import(
            /* webpackChunkName: "notFound" */
            "../features/errorPages/notFound/views/NotFoundPage.vue"
          ),
        meta: {
          unauthenticated: true,
          title: "not-found-page.page-title",
        },
      },
      {
        path: "/outdated-transport",
        name: PAGES.OUTDATED_TRANSPORT,
        component: () =>
          import(
            /* webpackChunkName: "outdatedTransport" */
            "../features/errorPages/outdatedTransport/views/OutdatedTransportPage.vue"
          ),
        meta: {
          title: "outdated-transport.page-title",
        },
      },
      {
        path: "/server-error",
        name: PAGES.SERVER_ERROR,
        component: () =>
          import(
            /* webpackChunkName: "serverError" */
            "../features/errorPages/serverError/views/ServerErrorPage.vue"
          ),
        meta: {
          unauthenticated: true,
          title: "server-error-page.page-title",
        },
      },
      {
        path: "/transports/ongoing/live-map",
        name: PAGES.LIVE_MAP,
        component: () =>
          import(
            /* webpackChunkName: "liveMap" */
            "../features/liveMap/views/LiveMap.vue"
          ),
        meta: { title: "live-map.page-title" },
      },
      {
        path: "/locations/:locationArrivalId/arrivals",
        name: TRANSPORT_PAGES.LOCATION_ARRIVAL_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "locationArrivals" */
            "../features/locationArrivals/views/LocationArrivals.vue"
          ),
        meta: { title: "location-arrival.title" },
        beforeEnter: isShipperGuard,
      },
      {
        path: "/callback",
        name: "Auth0Callback",
        component: () =>
          import(
            /* webpackChunkName: "callback" */
            "../features/auth/views/Callback.vue"
          ),
        meta: { unauthenticated: true, title: "authentication.page-title.logging-in" },
      },
    ],
  },
  {
    path: "",
    name: "default-layout",
    component: Default,
    children: [
      {
        path: "/cockpit",
        name: PAGES.COCKPIT,
        component: () =>
          import(
            /* webpackChunkName: "cockpit" */
            "../features/cockpit/views/Cockpit.vue"
          ),
        meta: { title: "cockpit.page-title" },
      },
      {
        path: "/transports/watchlist",
        name: TRANSPORT_PAGES.WATCHLIST,
        component: () =>
          import(
            /* webpackChunkName: "watchlist" */
            "../features/watchlist/views/Watchlist.vue"
          ),
        meta: { title: "transports.page-title.watchlist" },
      },
      {
        path: "/transports/action-required",
        name: TRANSPORT_PAGES.ACTION_REQUIRED_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "actionRequiredTransports" */
            "../features/transports/views/ActionRequiredTransports.vue"
          ),
        meta: { title: "transports.page-title.action-required-transports" },
      },
      {
        path: "/transports/action-required/gps-lost",
        name: TRANSPORT_PAGES.GPS_LOST_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "gpsLostTransports" */
            "../features/transports/views/GpsLostTransports.vue"
          ),
        meta: { title: "transports.page-title.gps-lost" },
      },
      {
        path: "/transports/action-required/gps-low",
        name: TRANSPORT_PAGES.GPS_LOW_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "gpsLowTransports" */
            "../features/transports/views/GpsLowTransports.vue"
          ),
        meta: { title: "transports.page-title.gps-low" },
      },
      {
        path: "/transports/action-required/gps-missing",
        name: TRANSPORT_PAGES.GPS_MISSING_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "gpsMissingTransports" */
            "../features/transports/views/GpsMissingTransports.vue"
          ),
        meta: { title: "transports.page-title.gps-missing" },
      },
      {
        path: "/transports/action-required/tracking-id-missing",
        name: TRANSPORT_PAGES.TRACKING_ID_MISSING_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "trackingIdMissingTransports" */
            "../features/transports/views/TrackingIdMissingTransports.vue"
          ),
        meta: { title: "transports.page-title.tracking-id-missing" },
      },
      {
        path: "/transports/action-required/waypoint-confirmation",
        name: TRANSPORT_PAGES.WAYPOINT_CONFIRMATION_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "waypointConfirmationTransports" */
            "../features/transports/views/WaypointConfirmationTransports.vue"
          ),
        meta: { title: "transports.page-title.waypoint-confirmation" },
        beforeEnter: isShipperGuard,
      },
      {
        path: "/transports/cancelled",
        name: TRANSPORT_PAGES.CANCELLED_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "cancelledTransports" */
            "../features/transports/views/CancelledTransports.vue"
          ),
        meta: { title: "transports.page-title.cancelled-transports" },
      },
      {
        path: "/transports/completed",
        name: TRANSPORT_PAGES.COMPLETED_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "completedTransports" */
            "../features/transports/views/CompletedTransports.vue"
          ),
        meta: { title: "transports.page-title.completed-transports" },
      },
      {
        path: "/transports/completed/arrived",
        name: TRANSPORT_PAGES.ARRIVED_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "arrivedTransports" */
            "../features/transports/views/ArrivedTransports.vue"
          ),
        meta: { title: "transports.page-title.arrived-transports" },
      },
      {
        path: "/transports/completed/area-arrived",
        name: TRANSPORT_PAGES.AREA_ARRIVED_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "areaArrivedTransports" */
            "../features/transports/views/AreaArrivedTransports.vue"
          ),
        meta: { title: "transports.page-title.area-arrived-transports" },
      },
      {
        path: "/transports/completed/manually-completed",
        name: TRANSPORT_PAGES.MANUALLY_COMPLETED_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "manuallyCompletedTransports" */
            "../features/transports/views/ManuallyCompletedTransports.vue"
          ),
        meta: { title: "transports.page-title.manually-completed-transports" },
      },
      {
        path: "/transports/completed/overdue",
        name: TRANSPORT_PAGES.OVERDUE_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "overdueTransports" */
            "../features/transports/views/OverdueTransports.vue"
          ),
        meta: { title: "transports.page-title.overdue-transports" },
      },
      {
        path: "/transports/incoming",
        component: () =>
          import(
            /* webpackChunkName: "incomingTransports" */
            "../features/transports/views/IncomingTransports.vue"
          ),
        children: [
          {
            path: "",
            name: TRANSPORT_PAGES.INCOMING_TRANSPORTS,
            component: () =>
              import(
                /* webpackChunkName: "incomingTransports" */
                "../features/transports/views/TrackableIncomingTransports.vue"
              ),
            meta: { title: "transports.page-title.incoming-transports" },
          },
          {
            path: "presumably",
            name: TRANSPORT_PAGES.PRESUMABLY_INCOMING_TRANSPORTS,
            component: () =>
              import(
                /* webpackChunkName: "incomingTransports" */
                "../features/transports/views/PresumablyIncomingTransports.vue"
              ),
            meta: { title: "transports.page-title.incoming-transports" },
          },
        ],
      },
      {
        path: "/transports/ongoing",
        component: () =>
          import(
            /* webpackChunkName: "ongoingTransports" */
            "../features/transports/views/OngoingTransports.vue"
          ),
        children: [
          {
            path: "",
            name: TRANSPORT_PAGES.ONGOING_TRANSPORTS,
            component: () =>
              import(
                /* webpackChunkName: "ongoingTransports" */
                "../features/transports/views/TrackableOngoingTransports.vue"
              ),
            meta: { title: "transports.page-title.ongoing-transports" },
          },
          {
            path: "presumably",
            name: TRANSPORT_PAGES.PRESUMABLY_ONGOING_TRANSPORTS,
            component: () =>
              import(
                /* webpackChunkName: "ongoingTransports" */
                "../features/transports/views/PresumablyOngoingTransports.vue"
              ),
            meta: { title: "transports.page-title.ongoing-transports" },
          },
        ],
      },
      {
        path: "/transports/planned",
        name: TRANSPORT_PAGES.PLANNED_TRANSPORTS,
        component: () =>
          import(
            /* webpackChunkName: "plannedTransports" */
            "../features/transports/views/PlannedTransports.vue"
          ),
        meta: { title: "transports.page-title.planned-transports" },
      },
      {
        path: "/search",
        name: TRANSPORT_PAGES.SEARCH_RESULTS,
        component: () =>
          import(
            /* webpackChunkName: "searchResults" */
            "../features/search/views/SearchResults.vue"
          ),
        meta: { title: "search-results.page-title" },
      },
      {
        path: "/transports/:transportId",
        component: () =>
          import(
            /* webpackChunkName: "transportDetails" */
            "../features/transportDetails/views/Transport.vue"
          ),
        children: [
          {
            path: "",
            name: PAGES.TRANSPORT_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "transportDetails" */
                "../features/transportDetails/views/TransportDetails.vue"
              ),
            meta: { title: "transport-details.page-title" },
          },
          {
            path: "waypoint/:waypointId",
            name: PAGES.WAYPOINT_DETAILS,
            component: () =>
              import(
                /* webpackChunkName: "transportDetails" */
                "../features/transportDetails/views/WaypointDetails.vue"
              ),
            meta: { title: "transport-details.page-title" },
          },
        ],
      },
      {
        path: "/settings/carriers",
        name: PAGES.CARRIERS_SETTINGS,
        component: () =>
          import(
            /* webpackChunkName: "carriersSettings" */
            "../features/carriers/views/CarriersSettings.vue"
          ),
        meta: { title: "settings.carriers.page-title" },
        beforeEnter: isShipperGuard,
      },
      {
        path: "/settings/notifications",
        name: PAGES.NOTIFICATIONS_SETTINGS,
        component: () =>
          import(
            /* webpackChunkName: "notificationsSettings" */
            "../features/notifications/views/NotificationsSettings.vue"
          ),
        meta: { title: "settings.notifications.page-title" },
      },
      {
        path: "/settings/shippers",
        name: PAGES.AGREED_SHIPPERS,
        component: () =>
          import(
            /* webpackChunkName: "agreedShippers" */
            "../features/agreements/views/AgreedShippers.vue"
          ),
        meta: { title: "settings.agreed-shippers.page-title" },
        beforeEnter: isCarrierGuard,
      },
      {
        path: "/settings/agreement-requests",
        component: () =>
          import(
            /* webpackChunkName: "agreementRequests" */
            "../features/agreements/views/AgreementRequests.vue"
          ),
        beforeEnter: isCarrierGuard,
        children: [
          {
            path: "",
            name: PAGES.AGREEMENT_REQUESTS,
            component: () =>
              import(
                /* webpackChunkName: "agreementRequests" */
                "../features/agreements/views/CurrentAgreementRequests.vue"
              ),
            meta: { title: "settings.agreement-requests.page-title" },
          },
          {
            path: "history",
            name: PAGES.AGREEMENT_REQUEST_HISTORY,
            component: () =>
              import(
                /* webpackChunkName: "agreementRequests" */
                "../features/carriers/views/AgreementRequestHistory.vue"
              ),
            meta: { title: "settings.agreement-request-history.page-title" },
          },
        ],
      },
      {
        path: "/settings/gps-signal-connection",
        name: PAGES.CARRIER_GPS_SIGNAL_CONNECTION,
        component: () =>
          import(
            /* webpackChunkName: "carrierGpsSignalConnection" */
            "../features/carrierGpsSignalConnection/views/CarrierGpsSignalConnection.vue"
          ),
        meta: { title: "settings.carrier-gps-signal-connection.page-title" },
      },
      // // Removed reporting menu access
      // {
      //   path: "/punctuality",
      //   name: PAGES.PUNCTUALITY,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "reportPunctuality" */
      //       "../features/reporting/Punctuality.vue"
      //     ),
      //   beforeEnter: isShipperGuard,
      //   meta: { title: "navigation.punctuality.page-title" },
      // },
      // {
      //   path: "/carrier-performance",
      //   name: PAGES.CARRIER_PERFORMANCE,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "reportCarrierPerformance" */
      //       "../features/reporting/CarrierPerformance.vue"
      //     ),
      //   beforeEnter: isShipperGuard,
      //   meta: { title: "navigation.carrierPerformance.page-title" },
      // },
      // {
      //   path: "/volume-report",
      //   name: PAGES.VOLUME_REPORT,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "reportVolumeReport" */
      //       "../features/reporting/VolumeReport.vue"
      //     ),
      //   beforeEnter: isShipperGuard,
      //   meta: { title: "navigation.volumeReport.page-title" },
      // },
      // {
      //   path: "/billing-report",
      //   name: PAGES.BILLING_REPORT,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "reportBillingReport" */
      //       "../features/reporting/BillingReport.vue"
      //     ),
      //   beforeEnter: isShipperGuard,
      //   meta: { title: "navigation.billingReport.page-title" },
      // },
      {
        path: "/faq",
        name: PAGES.FAQ,
        component: () =>
          import(
            /* webpackChunkName: "faq" */
            "../features/faq/views/Faq.vue"
          ),
        meta: { title: "common.faq" },
        // TODO: remove this when "faq" feature flag is removed
        beforeEnter: function guard(to, from, next) {
          const { enabled } = store.state.features;
          if (enabled && enabled["faq"]) {
            next();
            return;
          }

          next({ name: PAGES.NOT_FOUND });
        },
      },
      {
        path: "/webhooks",
        name: PAGES.WEBHOOKS,
        component: () =>
          import(
            /* webpackChunkName: "webhooks" */
            "../features/webhooks/views/Webhooks.vue"
          ),
        meta: { title: "webhooks.page-title" },
        beforeEnter: isShipperGuard,
      },
      {
        path: "*",
        redirect: "/not-found",
      },
    ],
  },
];

const routes = adminRoutes.concat(appRoutes);
export default routes;
