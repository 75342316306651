<template>
  <section class="bg-white transition-ease-out" :class="{ show: showMenu }">
    <router-link :class="this.linkClass('COCKPIT')" :to="{ name: PAGES.COCKPIT }" @click.native="toggleNavMenu" exact>
      <GaugeIcon size="20" class="mr-2 text-dark-blue-300" />
      {{ $t("navigation.cockpit") }}
    </router-link>

    <router-link
      :class="this.linkClass('WATCHLIST')"
      :to="{ name: PAGES.WATCHLIST }"
      @click.native="toggleNavMenu"
      exact
    >
      <Icon type="star" size="20" class="mr-2 text-dark-blue-300" />
      {{ $t("navigation.watchlist") }}
      <span class="ml-2 font-weight-normal">{{ watchedTransportsCount }}</span>
    </router-link>

    <h2 class="h6 text text-uppercase text-primary-blue font-weight-bold p-3 mb-0 tracking-widest">
      {{ $t("navigation.transports") }}
    </h2>

    <nav class="nav flex-column">
      <router-link
        :class="this.linkClass('ACTION_REQUIRED')"
        :to="{ name: PAGES.ACTION_REQUIRED_TRANSPORTS }"
        @click.native="toggleNavMenu"
        exact
      >
        <Icon type="alert-triangle" size="20" class="mr-2 text-dark-blue-300" />
        {{ $t("navigation.action-required") }}
        <span class="ml-2 font-weight-normal" v-if="actionRequiredTransportsCount >= 0">
          {{ actionRequiredTransportsCount }}
        </span>
      </router-link>

      <router-link
        v-if="isActionRequiredSubcategory"
        class="nav-link-secondary"
        :class="secondaryLinkClass('ACTION_REQUIRED.TRACKING_ID_MISSING')"
        :to="{ name: PAGES.TRACKING_ID_MISSING_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        {{ $t("navigation.tracking-id-missing") }}
        <span class="ml-2 font-weight-normal" v-if="trackingIdMissingCount >= 0">{{ trackingIdMissingCount }}</span>
      </router-link>

      <router-link
        v-if="isActionRequiredSubcategory"
        class="nav-link-secondary"
        :class="secondaryLinkClass('ACTION_REQUIRED.GPS_SIGNAL_MISSING')"
        :to="{ name: PAGES.GPS_MISSING_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        {{ $t("navigation.gps-missing") }}
        <span class="ml-2 font-weight-normal" v-if="gpsMissingCount >= 0">{{ gpsMissingCount }}</span>
      </router-link>

      <router-link
        v-if="isActionRequiredSubcategory"
        class="nav-link-secondary"
        :class="secondaryLinkClass('ACTION_REQUIRED.GPS_SIGNAL_LOST')"
        :to="{ name: PAGES.GPS_LOST_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        {{ $t("navigation.gps-lost") }}
        <span class="ml-2 font-weight-normal" v-if="gpsLostCount >= 0">{{ gpsLostCount }}</span>
      </router-link>

      <router-link
        v-if="isActionRequiredSubcategory"
        class="nav-link-secondary"
        :class="secondaryLinkClass('ACTION_REQUIRED.GPS_SIGNAL_LOW')"
        :to="{ name: PAGES.GPS_LOW_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        {{ $t("navigation.gps-low") }}
        <span class="ml-2 font-weight-normal" v-if="gpsLowCount >= 0">{{ gpsLowCount }}</span>
      </router-link>

      <DisplayFor shipper>
        <router-link
          v-if="isActionRequiredSubcategory"
          class="nav-link-secondary"
          :class="secondaryLinkClass('ACTION_REQUIRED.WAYPOINT_CONFIRMATION')"
          :to="{ name: PAGES.WAYPOINT_CONFIRMATION_TRANSPORTS }"
          @click.native="toggleNavMenu"
        >
          {{ $t("navigation.waypoint-confirmation") }}
          <span class="ml-1 font-weight-normal" v-if="waypointConfirmationCount >= 0">{{
            waypointConfirmationCount
          }}</span>
        </router-link>
      </DisplayFor>

      <router-link
        :class="this.linkClass('PLANNED')"
        :to="{ name: PAGES.PLANNED_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        <PlannedTransportIcon size="20" class="mr-2 text-dark-blue-300" />
        {{ $t("navigation.planned") }}
        <span class="ml-2 font-weight-normal" v-if="plannedTransportsCount >= 0">{{ plannedTransportsCount }}</span>
      </router-link>

      <router-link
        :class="this.linkClass('INCOMING')"
        :to="{ name: PAGES.INCOMING_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        <Icon type="radio" size="20" class="mr-2 text-dark-blue-300" />
        {{ $t("navigation.incoming") }}
        <span class="ml-2 font-weight-normal" v-if="incomingTransportsCount >= 0">{{ incomingTransportsCount }}</span>
      </router-link>

      <router-link
        :class="this.linkClass('ONGOING')"
        :to="{ name: PAGES.ONGOING_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        <OngoingTransportIcon size="20" class="mr-2 text-dark-blue-300" />
        {{ $t("navigation.ongoing") }}
        <span class="ml-2 font-weight-normal" v-if="ongoingTransportsCount >= 0">{{ ongoingTransportsCount }}</span>
      </router-link>

      <router-link
        :class="this.linkClass('COMPLETED')"
        :to="{ name: PAGES.COMPLETED_TRANSPORTS }"
        @click.native="toggleNavMenu"
        exact
      >
        <Icon type="check-circle" size="20" class="mr-2 text-dark-blue-300" />
        {{ $t("navigation.completed") }}
        <span class="ml-2 font-weight-normal" v-if="completedTransportsCount >= 0">{{ completedTransportsCount }}</span>
      </router-link>

      <router-link
        v-if="isCompletedTransportSubcategory"
        class="nav-link-secondary"
        :class="secondaryLinkClass('COMPLETED.ARRIVED')"
        :to="{ name: PAGES.ARRIVED_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        {{ $t("navigation.arrived") }}
        <span class="ml-2 font-weight-normal">{{ arrivedTransportsCount }}</span>
      </router-link>

      <router-link
        v-if="isCompletedTransportSubcategory"
        class="nav-link-secondary"
        :class="secondaryLinkClass('COMPLETED.AREA_ARRIVED')"
        :to="{ name: PAGES.AREA_ARRIVED_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        {{ $t("navigation.area-arrived") }}
        <span class="ml-2 font-weight-normal">{{ areaArrivedTransportsCount }}</span>
      </router-link>

      <router-link
        v-if="isCompletedTransportSubcategory"
        class="nav-link-secondary"
        :class="secondaryLinkClass('COMPLETED.MANUALLY_COMPLETED')"
        :to="{ name: PAGES.MANUALLY_COMPLETED_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        {{ $t("navigation.manually-completed") }}
        <span class="ml-2 font-weight-normal">{{ manuallyCompletedTransportsCount }}</span>
      </router-link>

      <router-link
        v-if="isCompletedTransportSubcategory"
        class="nav-link-secondary"
        :class="secondaryLinkClass('COMPLETED.OVERDUE')"
        :to="{ name: PAGES.OVERDUE_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        {{ $t("navigation.overdue") }}
        <span class="ml-2 font-weight-normal">{{ overdueTransportsCount }}</span>
      </router-link>

      <h2 class="h6 text text-uppercase text-primary-blue font-weight-bold p-3 mb-0 tracking-widest">
        {{ $t("navigation.archive") }}
      </h2>

      <router-link
        :class="this.linkClass('CANCELLED')"
        :to="{ name: PAGES.CANCELLED_TRANSPORTS }"
        @click.native="toggleNavMenu"
      >
        <NoTrackingIcon size="20" class="mr-2 text-dark-blue-300" />
        {{ $t("navigation.cancelled-transports") }}
        <span class="ml-2 font-weight-normal" v-if="cancelledTransportsCount >= 0">
          {{ cancelledTransportsCount }}
        </span>
      </router-link>

      <h2 class="h6 text text-uppercase text-primary-blue font-weight-bold p-3 mb-0 tracking-widest">
        {{ $t("navigation.settings") }}
      </h2>

      <DisplayFor shipper>
        <div>
          <router-link
            :class="this.linkClass()"
            :to="{ name: PAGES.NOTIFICATIONS_SETTINGS }"
            @click.native="toggleNavMenu"
            exact
          >
            <Icon type="settings" size="1.25rem" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.notifications") }}
          </router-link>

          <router-link
            :class="this.linkClass()"
            :to="{ name: PAGES.CARRIERS_SETTINGS }"
            @click.native="toggleNavMenu"
            exact
          >
            <Icon type="package" size="1.25rem" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.carriers") }}
          </router-link>
        </div>
      </DisplayFor>

      <DisplayFor carrier>
        <div>
          <router-link
            :class="this.linkClass()"
            :to="{ name: PAGES.NOTIFICATIONS_SETTINGS }"
            @click.native="toggleNavMenu"
            exact
          >
            <Icon type="settings" size="1.25rem" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.notifications") }}
          </router-link>

          <router-link
            :class="this.linkClass()"
            :to="{ name: PAGES.AGREED_SHIPPERS }"
            @click.native="toggleNavMenu"
            exact
          >
            <Icon type="package" size="1.25rem" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.shippers") }}
          </router-link>

          <router-link
            class="nav-link-secondary"
            :class="secondaryLinkClass()"
            :to="{ name: PAGES.AGREEMENT_REQUESTS }"
            @click.native="toggleNavMenu"
            exact
          >
            {{ $t("navigation.agreement-requests") }}
            <span class="ml-2 font-weight-normal">{{ pendingAgreementRequestsCount }}</span>
          </router-link>

          <router-link
            :class="this.linkClass()"
            :to="{ name: PAGES.CARRIER_GPS_SIGNAL_CONNECTION }"
            @click.native="toggleNavMenu"
            exact
          >
            <Icon type="wifi" size="20" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.carrierGpsSignalConnection") }}
          </router-link>
        </div>
      </DisplayFor>

      <!-- <DisplayFor shipper>
        <div>
          <h2 class="h6 text text-uppercase text-primary-blue font-weight-bold p-3 mb-0 tracking-widest">
            {{ $t("navigation.reports") }}
          </h2>

          <router-link :class="this.linkClass()" :to="{ name: PAGES.PUNCTUALITY }" @click.native="toggleNavMenu" exact>
            <ReportingIcon size="20" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.punctuality.page-title") }}
          </router-link>

          <router-link
            :class="this.linkClass()"
            :to="{ name: PAGES.CARRIER_PERFORMANCE }"
            @click.native="toggleNavMenu"
            exact
          >
            <PerformanceGraphIcon size="20" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.carrierPerformance.page-title") }}
          </router-link>

          <router-link
            :class="this.linkClass()"
            :to="{ name: PAGES.VOLUME_REPORT }"
            @click.native="toggleNavMenu"
            exact
          >
            <VolumeIcon size="20" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.volumeReport.page-title") }}
          </router-link>
          <router-link
            :class="this.linkClass()"
            :to="{ name: PAGES.BILLING_REPORT }"
            @click.native="toggleNavMenu"
            exact
          >
            <BillingIcon size="20" class="mr-2 text-dark-blue-300" />
            {{ $t("navigation.billingReport.page-title") }}
          </router-link>
        </div>
      </DisplayFor> -->
    </nav>
  </section>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex";
import {
  GPS_SIGNAL_STATUS,
  hasWaypointsNeedingConfirmation,
  TRANSPORT_COMPLETION_REASONS,
  TRANSPORT_STATES,
} from "@/core/transport";
import { PAGES } from "@/router/pageNames";
import windowSizeMixin from "@/mixins/windowSizeMixin";
import DisplayFor from "@/components/DisplayFor";
import Icon from "@/components/Icons/Icon";
import NoTrackingIcon from "@/components/Icons/NoTrackingIcon";
import PlannedTransportIcon from "@/components/Icons/PlannedTransportIcon";
import OngoingTransportIcon from "@/components/Icons/OngoingTransportIcon";
// // Removed reporting menu access
// import ReportingIcon from "@/components/Icons/ReportingIcon";
// import PerformanceGraphIcon from "@/components/Icons/PerformanceGraphIcon";
// import VolumeIcon from "@/components/Icons/VolumeIcon";
// import BillingIcon from "@/components/Icons/BillingIcon";
import GaugeIcon from "@/components/Icons/GaugeIcon";
const { mapState: mapTransportDetailsState } = createNamespacedHelpers("transportDetails");
const { mapState: mapFeaturesState } = createNamespacedHelpers("features");
const { mapGetters: mapAgreementsGetters, mapState: mapAgreementState } = createNamespacedHelpers("agreements");

export default {
  name: "Navigation",

  components: {
    // BillingIcon,
    DisplayFor,
    Icon,
    NoTrackingIcon,
    OngoingTransportIcon,
    PlannedTransportIcon,
    // // Removed reporting menu access
    // ReportingIcon,
    // PerformanceGraphIcon,
    // VolumeIcon,
    GaugeIcon,
  },

  mixins: [windowSizeMixin],

  data() {
    return {
      PAGES,
      selectedTransportState: null,
    };
  },

  computed: {
    ...mapFeaturesState({
      features: "enabled",
    }),
    ...mapState({
      watchedTransportsCount(state) {
        const count = state.watchlist?.transports?.totalElements;
        return count === null ? 0 : count;
      },

      actionRequiredTransportsCount(state) {
        const count = state.actionRequiredTransports?.transports?.totalElements;
        return count === null ? 0 : count;
      },

      areaArrivedTransportsCount(state) {
        const count = state.areaArrivedTransports?.transports?.totalElements;
        return count == null ? 0 : count;
      },

      arrivedTransportsCount(state) {
        const count = state.arrivedTransports?.transports?.totalElements;
        return count == null ? 0 : count;
      },

      cancelledTransportsCount(state) {
        return state.cancelledTransports?.transports?.totalElements;
      },

      completedTransportsCount(state) {
        return state.completedTransports?.transports?.totalElements;
      },

      gpsLostCount(state) {
        return state.gpsLostTransports?.transports?.totalElements;
      },

      gpsLowCount(state) {
        return state.gpsLowTransports?.transports?.totalElements;
      },

      waypointConfirmationCount(state) {
        return state.waypointConfirmations?.transports?.totalElements;
      },

      gpsMissingCount(state) {
        return state.gpsMissingTransports?.transports?.totalElements;
      },

      incomingTransportsCount(state) {
        return (
          state.incomingTransports?.transports?.totalElements +
          state.presumablyIncomingTransports?.transports?.totalElements
        );
      },

      manuallyCompletedTransportsCount(state) {
        const count = state.manuallyCompletedTransports?.transports?.totalElements;
        return count == null ? 0 : count;
      },

      ongoingTransportsCount(state) {
        return (
          state.ongoingTransports?.transports?.totalElements +
          state.presumablyOngoingTransports?.transports?.totalElements
        );
      },

      overdueTransportsCount(state) {
        const count = state.overdueTransports?.transports?.totalElements;
        return count == null ? 0 : count;
      },

      plannedTransportsCount(state) {
        return state.plannedTransports?.transports?.totalElements;
      },

      showMenu(state) {
        if (this.windowSize.width >= 1280) {
          return true;
        }

        return state.ui.showNavMenu;
      },

      trackingIdMissingCount(state) {
        return state.trackingIdMissingTransports?.transports?.totalElements;
      },
    }),

    ...mapTransportDetailsState({
      selectedTransport(state) {
        return state.transportDetails;
      },
    }),

    ...mapAgreementsGetters(["pendingAgreementRequests"]),
    ...mapAgreementState(["fetchingAgreementRequestsSucceeded"]),

    pendingAgreementRequestsCount() {
      return this.fetchingAgreementRequestsSucceeded ? this.pendingAgreementRequests.length : "";
    },

    isActionRequiredSubcategory() {
      return (
        this.$route.path.startsWith("/transports/action-required") ||
        this.selectedTransportState?.startsWith("ACTION_REQUIRED")
      );
    },

    isCompletedTransportSubcategory() {
      return (
        this.$route.path.startsWith("/transports/completed") ||
        this.selectedTransportState?.startsWith(TRANSPORT_STATES.COMPLETED)
      );
    },
  },

  watch: {
    /*
     * This watch is for when a user directly navigates to a Transport Details
     * page without using the navigation menu, for instance by pasting in a url or refreshing the page.
     * In this case the $route watch isn't triggered and we have to decide which
     * menu item to highlight based on the state of the transport.
     */
    selectedTransport(newTransport) {
      /*
       * This is for the case when the $route watch already has set the selectedTransportState.
       * The $route watch is always called before this watch, so if it is set then we don't need
       * to set it again.
       */
      if (this.selectedTransportState) {
        return;
      }

      if (
        newTransport !== null &&
        this.$route.path.startsWith("/transports/") &&
        this.$route.params["transportId"] !== null
      ) {
        if (newTransport.status === TRANSPORT_STATES.CANCELLED) {
          this.selectedTransportState = TRANSPORT_STATES.CANCELLED;
          return;
        }

        if (newTransport.status === TRANSPORT_STATES.COMPLETED) {
          this.selectedTransportState = `${TRANSPORT_STATES.COMPLETED}.${newTransport.completionReason}`;
          return;
        }

        if (newTransport.status === TRANSPORT_STATES.PLANNED) {
          this.selectedTransportState = TRANSPORT_STATES.PLANNED;
          return;
        }

        if (hasWaypointsNeedingConfirmation(newTransport)) {
          this.selectedTransportState = "ACTION_REQUIRED.WAYPOINT_CONFIRMATION";
          return;
        }

        if (newTransport.gpsSignalStatus !== GPS_SIGNAL_STATUS.GPS_SIGNAL_OK) {
          this.selectedTransportState = `ACTION_REQUIRED.${newTransport.gpsSignalStatus}`;
          return;
        }

        if (newTransport)
          if (newTransport.status === TRANSPORT_STATES.ONGOING) {
            this.selectedTransportState = TRANSPORT_STATES.ONGOING;
            return;
          }

        if (newTransport.status === TRANSPORT_STATES.INCOMING) {
          this.selectedTransportState = TRANSPORT_STATES.INCOMING;
          return;
        }
      }

      this.selectedTransportState = null;
    },

    /*
     * This watch is for setting the highlighted menu item based on
     * where the user is coming from.
     * Example:
     * If user is on /transports/gps-low and clicks on a transport then
     * GPS Low should still be highlighted in the menu even if the transport might have another state.
     */
    $route(to, from) {
      if (to.path.startsWith("/transports/") && to.params["transportId"]) {
        switch (from.path) {
          case "/transports/watchlist":
            this.selectedTransportState = "WATCHLIST";
            break;
          case "/transports/action-required":
            this.selectedTransportState = "ACTION_REQUIRED";
            break;
          case "/transports/action-required/tracking-id-missing":
            this.selectedTransportState = `ACTION_REQUIRED.${GPS_SIGNAL_STATUS.TRACKING_ID_MISSING}`;
            break;
          case "/transports/action-required/gps-missing":
            this.selectedTransportState = `ACTION_REQUIRED.${GPS_SIGNAL_STATUS.GPS_SIGNAL_MISSING}`;
            break;
          case "/transports/action-required/gps-low":
            this.selectedTransportState = `ACTION_REQUIRED.${GPS_SIGNAL_STATUS.GPS_SIGNAL_LOW}`;
            break;
          case "/transports/action-required/gps-lost":
            this.selectedTransportState = `ACTION_REQUIRED.${GPS_SIGNAL_STATUS.GPS_SIGNAL_LOST}`;
            break;
          case "/transports/action-required/waypoint-confirmation":
            this.selectedTransportState = "ACTION_REQUIRED.WAYPOINT_CONFIRMATION";
            break;
          case "/transports/planned":
            this.selectedTransportState = TRANSPORT_STATES.PLANNED;
            break;
          case "/transports/incoming":
            this.selectedTransportState = TRANSPORT_STATES.INCOMING;
            break;
          case "/transports/ongoing":
            this.selectedTransportState = TRANSPORT_STATES.ONGOING;
            break;
          case "/transports/completed":
            this.selectedTransportState = TRANSPORT_STATES.COMPLETED;
            break;
          case "/transports/cancelled":
            this.selectedTransportState = TRANSPORT_STATES.CANCELLED;
            break;
          case "/transports/completed/arrived":
            this.selectedTransportState = `${TRANSPORT_STATES.COMPLETED}.${TRANSPORT_COMPLETION_REASONS.ARRIVED}`;
            break;
          case "/transports/completed/area-arrived":
            this.selectedTransportState = `${TRANSPORT_STATES.COMPLETED}.${TRANSPORT_COMPLETION_REASONS.AREA_ARRIVED}`;
            break;
          case "/transports/completed/manually-completed":
            this.selectedTransportState = `${TRANSPORT_STATES.COMPLETED}.${TRANSPORT_COMPLETION_REASONS.MANUALLY_COMPLETED}`;
            break;
          case "/transports/completed/overdue":
            this.selectedTransportState = `${TRANSPORT_STATES.COMPLETED}.${TRANSPORT_COMPLETION_REASONS.OVERDUE}`;
            break;
          default:
            this.selectedTransportState = null;
        }

        return;
      }

      this.selectedTransportState = null;
    },
  },

  methods: {
    isSelectedState(state) {
      return state !== null && state === this.selectedTransportState;
    },

    linkClass(transportState) {
      const activeClass = this.isSelectedState(transportState) ? " router-link-active" : "";
      return `nav-link p-3 d-block text-gray-400 font-weight-bold d-flex align-items-center${activeClass}`;
    },

    secondaryLinkClass(transportState) {
      const activeClass = this.isSelectedState(transportState) ? " router-link-active" : "";
      return `nav-link p-3 d-block text-gray-400 d-flex align-items-center${activeClass}`;
    },

    toggleNavMenu() {
      this.$store.dispatch("toggleNavMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
$site-header-height: 4.2375rem;

section {
  height: calc(100% - #{$site-header-height});
  left: -100%;
  overflow-y: auto;
  position: fixed;
  top: 3.625rem;
  transition-property: left;
  width: 100%;
  z-index: $zindex-fixed;
}

@media (min-width: 640px) {
  section {
    left: -17rem;
    top: 4.25rem;
    width: 17rem;
  }
}

::v-deep(.nav-link:focus),
::v-deep(.nav-link:hover),
::v-deep(.router-link-active) {
  background-color: $color-secondary-blue;
  color: $white;
}

.nav-link-secondary {
  padding-left: map-get($spacers, 3) + map-get($spacers, 5) !important;
}

.show {
  left: 0;
}
</style>
