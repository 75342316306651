<template>
  <div id="app">
    <router-view></router-view>
    <ErrorToast :id="errorToastId" :error-message="error.message" :title="error.title" />
    <UnhandledErrorMessager />
    <VersionToast />
  </div>
</template>

<script>
import { mapActions, createNamespacedHelpers } from "vuex";
import ErrorToast from "@/components/Toasts/ErrorToast";
import UnhandledErrorMessager from "@/features/errorMessages/components/UnhandledErrorMessager";
import VersionToast from "@/features/versionCheck/components/VersionToast";

const { mapActions: mapAgreementsActions } = createNamespacedHelpers("agreements");
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapActions: mapFeaturesActions } = createNamespacedHelpers("features");
const { mapState: mapErrorMessagesState } = createNamespacedHelpers("errorMessages");
const { mapActions: mapNotificationsActions } = createNamespacedHelpers("notifications");
const { mapState: mapOrganizationState } = createNamespacedHelpers("organization");
const { mapGetters: mapVersionCheckGetters } = createNamespacedHelpers("versionCheck");
const { mapActions: mapWatchlistActions } = createNamespacedHelpers("watchlist");

export default {
  components: {
    ErrorToast,
    UnhandledErrorMessager,
    VersionToast,
  },

  data() {
    return {
      errorToastId: "global-error-message",
    };
  },

  computed: {
    ...mapAuthGetters(["isAuthenticated"]),

    ...mapVersionCheckGetters(["hasVersionMismatch"]),

    ...mapErrorMessagesState({
      error(state) {
        return {
          message: state.errorMessage,
          title: state.errorTitle,
        };
      },
    }),

    ...mapOrganizationState({
      orgRole(state) {
        return state.orgRole;
      },
    }),
  },

  watch: {
    error(newError, oldError) {
      if (newError.title !== oldError.title || newError.message !== oldError.message) {
        this.$bvToast.show(this.errorToastId);
      }
    },

    hasVersionMismatch(hasMismatch) {
      if (hasMismatch) {
        this.$bvToast.show("version-message");
      }
    },

    orgRole(newRole) {
      if (newRole) {
        this.fetchFeatures();
        this.getNotifications();
        this.getAllTransports();
        this.getWatchlist();
        this.getAgreements();
      }
    },
  },

  methods: {
    ...mapActions(["getAllTransports"]),
    ...mapFeaturesActions(["fetchFeatures"]),
    ...mapNotificationsActions(["getNotifications"]),
    ...mapAgreementsActions(["getAgreements"]),
    ...mapWatchlistActions(["getWatchlist"]),
  },
};
</script>

<style lang="scss">
$flag-icons-path: "~flag-icon-css/flags";
@import "~design-kit";
@import "~flag-icon-css/sass/flag-icons.scss";
@import "~bootstrap-vue/src/index.scss";

/* The emerging W3C standard for scrollbar
   styling that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: $gray-400 $gray-200;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: $gray-200;
}
*::-webkit-scrollbar-thumb {
  background-color: $gray-400;
  border-radius: 20px;
}

#app {
  min-height: 100vh;
}

label[for] {
  cursor: pointer;
}

main {
  margin-left: 0;
  transition-property: margin-left;
}

body {
  background: $color-primary-gradient;
  font-weight: 700;
}

ol,
ul {
  margin-bottom: 0;
}

.header-space {
  padding-top: 3.625rem;
}

@media (min-width: 640px) {
  .header-space {
    padding-top: 4.25rem;
  }

  .nav-space {
    margin-left: 17rem;
  }
}

/* Work around for styling notifications popover because
 * popovers are appended to the end of body and can't be
 * accessed from inside a component style.
 */
.notifications-list-notification-menu {
  background-color: transparent !important;
  border-color: transparent !important;

  > .arrow {
    display: none !important;
  }
}
</style>
