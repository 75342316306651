import Vue from "vue";
import { setupHttpClient } from "@/core/api";
import "./directives/clickOutside";
import "./directives/detailsScroll";
import i18n, { useI18nHotReloading } from "./i18n";
import App from "./App.vue";
import router from "@/router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import { TRANSPORT_PAGES, PAGES } from "@/router/pageNames";
Vue.config.productionTip = false;
Vue.use(BootstrapVue);

import Logger from "@/core/logger";
const logger = Logger.getInstance();
Vue.prototype.logger = logger;
Vue.config.errorHandler = (err, vm, info) => {
  logger.error(err.message, err, info);
};
Vue.config.warnHandler = function (msg, vm, trace) {
  logger.warn(msg, trace);
};

setupHttpClient({
  getAccessToken() {
    return store.getters["auth/accessToken"];
  },

  onBadRequest() {
    const { currentPage } = store.state;
    logger.warn("Bad request occurred on page: ", currentPage);

    if ([...Object.values(TRANSPORT_PAGES), PAGES.TRANSPORT_DETAILS].includes(currentPage)) {
      // router.replace({ name: PAGES.NOT_FOUND });// TODO only redirect to error pages on user interactions. Not for any background call.
    }
  },

  onUnauthorized() {
    if (store.getters["auth/isTokenExpired"]) {
      logger.warn("The JWT has expired");
      store.dispatch("auth/logout");
      return;
    }

    store.dispatch("errorMessages/displayError", {
      errorTitle: i18n.t("global-error-messages.unauthorized.title"),
      errorMessage: i18n.t("global-error-messages.unauthorized.message"),
    });
  },
});

const unauthenticatedRoutes = router.options.routes?.filter((r) => r?.meta?.unauthenticated) || [];
const unnamedRoutes = (r) => !r.name;

if (unauthenticatedRoutes.filter(unnamedRoutes).length > 0) {
  throw new Error(
    `Unauthenticated routes need to be named to disable tracking. Add a name property to these routes ${unauthenticatedRoutes
      .filter(unnamedRoutes)
      .map((r) => r.path)}`
  );
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

useI18nHotReloading();
