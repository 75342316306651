<template>
  <div class="align-items-center border rounded-sm d-flex flex-nowrap" :class="{ 'search-bar-focused': hasFocus }">
    <Icon
      class="text-gray-300 placeholder-icon"
      type="search"
      v-if="showPlaceholderIcon"
      size="20"
      aria-hidden="true"
    />

    <b-form-input
      ref="input"
      class="flex-grow-1 search-input"
      type="text"
      :aria-label="inputPlaceholder"
      :placeholder="inputPlaceholder"
      size="lg"
      v-model="searchText"
      @focus="hasFocus = true"
      @blur="hasFocus = false"
      @keydown.enter.native="doSearch"
    />

    <b-button variant="secondary" size="sm" class="d-flex align-items-center" v-if="showButton" @click="doSearch">
      <Icon type="search" size="20" class="mr-1" aria-hidden="true" />
      {{ $t("search-bar.button") }}
    </b-button>

    <ClearInputButton @click="clearClicked" :aria-label="$t('common.clear')" class="mx-2" v-if="showButton" />
  </div>
</template>

<script>
import Icon from "@/components/Icons/Icon";
import ClearInputButton from "@/components/Buttons/ClearInputButton";
import { mapGetters, createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("searchResults");

export default {
  components: { ClearInputButton, Icon },

  data: () => ({
    hasFocus: false,
    searchText: "",
  }),

  computed: {
    ...mapGetters(["isUserCarrier", "isUserShipper"]),

    inputPlaceholder() {
      if (this.isUserCarrier) {
        return this.$t("search-bar.carrier-placeholder");
      }

      if (this.isUserShipper) {
        return this.$t("search-bar.shipper-placeholder");
      }

      return "";
    },

    showPlaceholderIcon() {
      return this.hasFocus || this.searchText ? "" : "search";
    },

    showButton() {
      return this.searchText;
    },
  },

  methods: {
    ...mapActions(["search", "setPage"]),
    clearClicked() {
      this.searchText = "";
    },

    async doSearch() {
      this.setPage(0);
      this.search(this.searchText);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar-focused {
  border: 1px solid $color-secondary-blue !important;
  box-shadow: $box-shadow-sm;
}

.placeholder-icon {
  padding-top: map-get($spacers, 2) + map-get($spacers, 1) * 0.5;
  padding-bottom: map-get($spacers, 2) + map-get($spacers, 1) * 0.5;
  padding-left: map-get($spacers, 2);
}

.search-input {
  border: 0;
}

.search-input:focus {
  border: 0px;
  box-shadow: none;
}
</style>
