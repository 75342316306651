import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/i18n";
import store from "@/store";
import { isAuth0Enabled, authGuard } from "@/features/auth";
import routes from "@/router/routes";
import { PAGES } from "@/router/pageNames";
import Logger from "@/core/logger";
const logger = Logger.getInstance();

const PAGE_TITLE_PREFIX = "Wakeo TSER";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

if (isAuth0Enabled()) {
  router.beforeEach((to, from, next) =>
    authGuard({
      to,
      from,
      next,
      store,
    })
  );
}

router.beforeEach(async (to, from, next) => {
  store.dispatch("setCurrentPage", to.name);
  next();
});

router.beforeEach(async (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    await fetchFeatures();
  }
  next();
});

router.beforeEach((to, from, next) => {
  saveTableScrollPosition({ from, to });
  next();
});

router.afterEach((to) => {
  // The matched property is an array of all matched routes (because of nested routes).
  // The assumption here is that the last matched route is the one being navigated to
  // and contains the appropriate document title.
  const title = to.matched[to.matched.length - 1]?.meta?.title;

  setDocumentTitle(title);
});

// Watch for locale changes and re-translate the document title.
i18n.vm.$watch("locale", () => setDocumentTitle(router.history.current.meta?.title));

// The point here is to remember the scroll position when moving
// from/to TransportDetails but resetting it when not.
function saveTableScrollPosition({ from, to }) {
  // If we are moving to TransportDetails but not from WaypointDetails
  // as that is a subpage of the former, we save the scroll position of
  // the transport table.
  if (to.name === PAGES.TRANSPORT_DETAILS && from.name !== PAGES.WAYPOINT_DETAILS) {
    const tableScrollPosition = document.querySelector("[data-scroll-container]")?.scrollTop;
    store.dispatch("setTableScrollPosition", { page: from.name, position: tableScrollPosition });
    // If we are not moving to TransportDetails and we are not moving from TransportDetails
    // or its subpage WaypointDetails, we reset the saved scroll position.
  } else if (to.name !== PAGES.TRANSPORT_DETAILS && from.name !== (PAGES.TRANSPORT_DETAILS || PAGES.WAYPOINT_DETAILS)) {
    store.dispatch("setTableScrollPosition", { page: "", position: null });
  }
}

async function fetchFeatures() {
  //if the features haven't been loaded yet, they should be loaded, and let's wait until they are there
  if (Object.keys(store.state.features.enabled).length === 0) {
    await store.dispatch("features/fetchFeatures");
  }
}

function setDocumentTitle(key) {
  const title = i18n.t(key);
  if (!title) {
    logger.warn("The matched route is missing a document title. Did you forget to add a meta property to the route?");
    return;
  }

  document.title = `${PAGE_TITLE_PREFIX} | ${title}`;
}

export default router;
